import * as React from "react";
import { useState } from "react";
import {Pivot, PivotItem} from "@fluentui/react";
import {FontSizes} from "@fluentui/theme";
import Notification from "../../notifications/Notification";
import PrepaidProjects from "./PrepaidProjects/PrepaidProjects";
import WaitingClassicPaymentProjects from "./WaitingClassicPaymentProjects/WaitingClassicPaymentProjects";
import WaitingClosingProjects from "./WaitingClosingProjects/WaitingClosingProjects";
import OnlinePayments from "./OnlinePayments/OnlinePayments";

export default function FinanceMainSection() {
    const [activePivot, setActivePivot] = useState('paid');
    const [notification, setNotification] = useState({type: 'hidden', text: ''});

    const hash = window.location.hash && window.location.hash.substring(1);
    if (activePivot !== 'waiting' && hash === 'waiting') {
        setActivePivot('waiting');
    } else if (activePivot !== 'closing' && hash === 'closing') {
        setActivePivot('closing');
    } else if (activePivot !== 'online' && hash === 'online') {
        setActivePivot('online');
    }

    return (<>
        <div style={{ fontSize: FontSizes.size20, marginBottom: 20, fontWeight: 600 }}>Финансы</div>
        <Notification type={notification.type} text={notification.text} />
        <Pivot aria-label="Меню" selectedKey={activePivot} onLinkClick={(item) => {
            window.history.pushState('', '/', window.location.pathname);
            setActivePivot(item.props.itemKey);
        }}>
            <PivotItem headerText="Предоплаченные проекты" itemKey="paid">
                <PrepaidProjects />
            </PivotItem>
            <PivotItem headerText="Ожидают оплаты по документам" itemKey="waiting">
                <WaitingClassicPaymentProjects />
            </PivotItem>
            <PivotItem headerText="Подтвердить закрытие проекта" itemKey="closing">
                <WaitingClosingProjects setNotification={setNotification} />
            </PivotItem>
            <PivotItem headerText="Онлайн-платежи" itemKey="online">
               <OnlinePayments />
            </PivotItem>
        </Pivot>
    </>);
};
