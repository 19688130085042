import * as React from "react";
import {
    ActionButton, Callout,
    DatePicker,
    DayOfWeek,
    DetailsList,
    DetailsListLayoutMode, NormalPeoplePicker,
    PrimaryButton,
    SelectionMode,
    Separator,
    Stack,
    TextField
} from "@fluentui/react";
import changeProjectFinanceMethod from "../../../../../api/changeProjectFinanceMethod";
import MoneyField from "../../../../MoneyField";
import {FontSizes} from "@fluentui/theme";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import {useEffect, useState} from "react";
import getUserCollectionMethod from "../../../../../api/getUserCollectionMethod";
import {datePickerStringsRu} from "../../../../data/datePickerStringsRu";
import updateFinanceWorkerMethod from "../../../../../api/updateFinanceWorkerMethod";
import makePrepaymentMethod from "../../../../../api/makePrepaymentMethod";
import addFinanceWorkerMethod from "../../../../../api/addFinanceWorkerMethod";
import {FontIcon} from "@fluentui/react/lib/Icon";
import removeFinanceWorkerMethod from "../../../../../api/removeFinanceWorkerMethod";
import PaymentSteps from "./PaymentSteps";
import Comments from "../../../../Shared/Comments";
import {DefaultButton} from "@fluentui/react/lib/Button";

let estSpendsBefore = null;
let spendsBefore = null;

export default function Finance({project, setProject, setNotification, reloadData, canChangeFinanceFact = false, isShorty = false}) {
    const [users, setUsers] = useState({loaded: false, data: []});
    const [estSpendsChanged, setEstSpendsChanged] = useState(false);
    const [spendsChanged, setSpendsChanged] = useState(false);
    const [enableToChangePrepaymentDate, setEnableToChangePrepaymentDate] = useState(false);
    // const [isSelectInputCalloutVisible, { toggle: toggleSelectInputCallout, setFalse: hideSelectInputCallout }] = useBoolean(false);
    const [otherInput, setOtherInput] = useState({});

    if (!estSpendsChanged) {
        estSpendsBefore = project.data.finance.estimatedSummarySpending;
    }
    if (!spendsChanged) {
        spendsBefore = project.data.finance.summarySpending;
    }

    useEffect(() => {
        if (!users.loaded) {
            getUserCollectionMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setUsers({loaded: true, data: response});
            });
        }
    });

    const changeFinance = (key, value, value2 = null, workers = null) => {
        // setProject({...project, data: {...project.data, finance: {...project.data.finance, [key]: value}}});

        let summaryPrice = project.data.finance.summaryPrice;
        let estimatedSummarySpending = project.data.finance.estimatedSummarySpending;
        let summarySpending = project.data.finance.summarySpending;
        let summaryMargin = project.data.finance.summaryMargin;
        let marginPercent = project.data.finance.marginPercent;
        let estimatedSummaryMargin = project.data.finance.estimatedSummaryMargin;
        let estimatedMarginPercent = project.data.finance.estimatedMarginPercent;

        let prepaymentType = project.data.finance.prepayment.type;
        let prepaymentSum = project.data.finance.prepayment.sum;
        let prepaymentStatus = project.data.finance.prepayment.status;
        let prepaymentPaidAt = project.data.finance.prepayment.paidAt;

        let postPaymentType = project.data.finance.postPayment.type;
        let postPaymentSum = project.data.finance.postPayment.sum;
        let postPaymentStatus = project.data.finance.postPayment.status;
        let postPaymentPaidAt = project.data.finance.postPayment.paidAt;

        let prepaymentPercent, prepaymentTitle, postPaymentPercent, postPaymentTitle;

        switch (key) {
            case 'prepaymentType':
                prepaymentType = value;
                setProject({...project, data: {...project.data, finance: {...project.data.finance, prepayment: {...project.data.finance.prepayment, 'type': value}}}});
                break;
            case 'prepaymentSum':
                prepaymentSum = value;
                prepaymentPercent = parseInt(prepaymentSum / summaryPrice * 100);
                prepaymentTitle = prepaymentPercent === 100 ? 'Оплата' : 'Предоплата ' + prepaymentPercent + '%';
                setProject({...project, data: {...project.data, finance: {...project.data.finance, prepayment: {...project.data.finance.prepayment, 'sum': value, title: prepaymentTitle}}}});
                break;
            case 'prepaymentStatus':
                prepaymentStatus = value;
                setProject({...project, data: {...project.data, finance: {...project.data.finance, prepayment: {...project.data.finance.prepayment, 'status': value}}}});
                break;
            case 'prepaymentPaidAt':
                if (value === project.data.finance.prepayment.paidAt) {
                    prepaymentPaidAt = null;
                } else {
                    prepaymentPaidAt = value;
                }
                setProject({...project, data: {...project.data, finance: {...project.data.finance, prepayment: {...project.data.finance.prepayment, 'paidAt': prepaymentPaidAt}}}});
                break;
            case 'postPaymentType':
                postPaymentType = value;
                setProject({...project, data: {...project.data, finance: {...project.data.finance, postPayment: {...project.data.finance.postPayment, 'type': value}}}});
                break;
            case 'postPaymentSum':
                postPaymentSum = value;
                postPaymentPercent = parseInt(postPaymentSum / summaryPrice * 100);
                postPaymentTitle = 'Постоплата ' + postPaymentPercent + '%';
                setProject({...project, data: {...project.data, finance: {...project.data.finance, postPayment: {...project.data.finance.postPayment, 'sum': value, title: postPaymentTitle}}}});
                break;
            case 'postPaymentStatus':
                postPaymentStatus = value;
                setProject({...project, data: {...project.data, finance: {...project.data.finance, postPayment: {...project.data.finance.postPayment, 'status': value}}}});
                break;
            case 'postPaymentPaidAt':
                if (value === project.data.finance.postPayment.paidAt) {
                    postPaymentPaidAt = null;
                } else {
                    postPaymentPaidAt = value;
                }
                setProject({...project, data: {...project.data, finance: {...project.data.finance, postPayment: {...project.data.finance.postPayment, 'paidAt': postPaymentPaidAt}}}});
                break;
            case 'summaryPrice':
                summaryPrice = value;

                if (estimatedSummarySpending) {
                    estimatedSummaryMargin = summaryPrice - estimatedSummarySpending;
                    // estimatedSummaryMargin = estimatedSummaryMargin < 0 ? null : estimatedSummaryMargin;
                    estimatedMarginPercent = parseInt((100 / summaryPrice) * estimatedSummaryMargin);
                    // estimatedMarginPercent = estimatedMarginPercent < 0 ? null : estimatedMarginPercent;
                } else {
                    estimatedSummaryMargin = null;
                    estimatedMarginPercent = null;
                }

                if (summarySpending) {
                    summaryMargin = actualSummaryPrice - summarySpending;
                    // summaryMargin = summaryMargin < 0 ? null : summaryMargin;
                    marginPercent = parseInt((100 / actualSummaryPrice) * summaryMargin);
                    // marginPercent = marginPercent < 0 ? null : marginPercent;
                } else {
                    summaryMargin = null;
                    marginPercent = null;
                }

                prepaymentPercent = parseInt(prepaymentSum / summaryPrice * 100);
                prepaymentTitle = prepaymentPercent === 100 ? 'Оплата' : 'Предоплата ' + prepaymentPercent + '%';
                postPaymentPercent = parseInt(postPaymentSum / summaryPrice * 100);
                postPaymentTitle = 'Постоплата ' + postPaymentPercent + '%';

                setProject({
                    ...project,
                    data: {
                        ...project.data,
                        finance: {
                            ...project.data.finance,
                            [key]: value,
                            'estimatedSummaryMargin': estimatedSummaryMargin,
                            'estimatedMarginPercent': estimatedMarginPercent,
                            'summaryMargin': summaryMargin,
                            'marginPercent': marginPercent,
                            prepayment: {...project.data.finance.prepayment, title: prepaymentTitle},
                            postPayment: {...project.data.finance.postPayment, title: postPaymentTitle},
                        }
                    }
                });
                break;
            case 'estimatedSummarySpending':
                estimatedSummarySpending = value;

                if (estimatedSummarySpending) {
                    estimatedSummaryMargin = summaryPrice - estimatedSummarySpending;
                    // estimatedSummaryMargin = estimatedSummaryMargin < 0 ? null : estimatedSummaryMargin;
                    estimatedMarginPercent = parseInt((100 / summaryPrice) * estimatedSummaryMargin);
                    // estimatedMarginPercent = estimatedMarginPercent < 0 ? null : estimatedMarginPercent;
                } else {
                    estimatedSummaryMargin = null;
                    estimatedMarginPercent = null;
                }
                setEstSpendsChanged(true);
                setProject({...project, data: {...project.data, finance: {...project.data.finance, [key]: value, 'estimatedSummaryMargin': estimatedSummaryMargin, 'estimatedMarginPercent': estimatedMarginPercent}}});
                break;
            case 'summarySpending':
                summarySpending = value;

                if (summarySpending) {
                    summaryMargin = actualSummaryPrice - summarySpending;
                    // summaryMargin = summaryMargin < 0 ? null : summaryMargin;
                    marginPercent = parseInt((100 / actualSummaryPrice) * summaryMargin);
                    // marginPercent = marginPercent < 0 ? null : marginPercent;
                } else {
                    summaryMargin = null;
                    marginPercent = null;
                }
                setSpendsChanged(true);
                setProject({...project, data: {...project.data, finance: {...project.data.finance, [key]: value, 'summaryMargin': summaryMargin, 'marginPercent': marginPercent}}});
                break;
            // case 'summaryMargin':
            //     summaryMargin = value;
            //     break;
            // case 'marginPercent':
            //     marginPercent = value;
            //     break;
            // case 'estimatedSummaryMargin':
            //     estimatedSummaryMargin = value;
            //     break;
            // case 'estimatedMarginPercent':
            //     estimatedMarginPercent = value;
            //     break;
            case 'estimatedSummarySpending|summarySpending':
                estimatedSummarySpending = value;
                if (estimatedSummarySpending) {
                    estimatedSummaryMargin = summaryPrice - estimatedSummarySpending;
                    // estimatedSummaryMargin = estimatedSummaryMargin < 0 ? null : estimatedSummaryMargin;
                    estimatedMarginPercent = parseInt((100 / summaryPrice) * estimatedSummaryMargin);
                    // estimatedMarginPercent = estimatedMarginPercent < 0 ? null : estimatedMarginPercent;
                } else {
                    estimatedSummaryMargin = null;
                    estimatedMarginPercent = null;
                }

                summarySpending = value2;
                if (summarySpending) {
                    summaryMargin = actualSummaryPrice - summarySpending;
                    // summaryMargin = summaryMargin < 0 ? null : summaryMargin;
                    marginPercent = parseInt((100 / actualSummaryPrice) * summaryMargin);
                    // marginPercent = marginPercent < 0 ? null : marginPercent;
                } else {
                    summaryMargin = null;
                    marginPercent = null;
                }

                setEstSpendsChanged(true);
                setSpendsChanged(true);
                setProject({...project, data: {...project.data, finance: {...project.data.finance, estimatedSummarySpending: value, 'estimatedSummaryMargin': estimatedSummaryMargin, 'estimatedMarginPercent': estimatedMarginPercent, summarySpending: value2, 'summaryMargin': summaryMargin, 'marginPercent': marginPercent, workers: workers}}});
                break;
            default:
                break;
        }

        const data = {
            id: project.data.id,
            summaryPrice: summaryPrice,
            estimatedSummarySpending: estimatedSummarySpending,
            summarySpending: summarySpending,
            summaryMargin: summaryMargin,
            marginPercent: marginPercent,
            estimatedSummaryMargin: estimatedSummaryMargin,
            estimatedMarginPercent: estimatedMarginPercent,
            prepaymentType: prepaymentType,
            prepaymentSum: prepaymentSum,
            prepaymentStatus: prepaymentStatus,
            prepaymentPaidAt: prepaymentPaidAt,
            postPaymentType: postPaymentType,
            postPaymentSum: postPaymentSum,
            postPaymentStatus: postPaymentStatus,
            postPaymentPaidAt: postPaymentPaidAt,
        };

        changeProjectFinanceMethod(data).then(response => {
            if (response.exception || response.error) {
                console.log(response.error ? response.error.message : response.exception);
                setNotification({type: 'error', text: 'Ошибка сервера'});
                return;
            }

            setNotification({type: 'success', text: 'Данные сохранены'});
        });
    };

    const getCurrentParticipantUsersByType = (type) => {
        const participants = project.data.participants.filter((participant) => participant.type === type);
        const collection = [];

        for (const participant of participants) {
            for (const user of users.data) {
                if (user.id === participant.userId) {
                    collection.push({
                        id: user.id,
                        projectsCount: user.projectsCount,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        isValid: true,
                        key: user.id,
                        text: user.firstName + ' ' + (user.lastName ?? ''),
                    })
                }
            }
        }

        return collection;
    };

    const changeWorker = async (id, key, value) => {
        let workerId;
        const workers = project.data.finance.workers.map((worker) => {
            if (worker.id === id) {
                if ((key === 'userId' && worker.userId === null) || (key === 'username' && worker.userId !== null)) {
                    setOtherInput({...otherInput, [worker.id]: false});
                }
                if (key === 'username') {
                    worker.userId = null;
                    workerId = worker.id;
                }
                worker[key] = value;
                if (key === 'grossPrice') {
                    worker['grossPriceUpdatedAt'] = Date.now().valueOf();
                }
                if (key === 'planPrice') {
                    worker['planPriceUpdatedAt'] = Date.now().valueOf();
                    if (!worker['planPriceCreatedAt']) {
                        worker['planPriceCreatedAt'] = Date.now().valueOf();
                    }
                }
            }
            return worker;
        });

        setProject({...project, data: {...project.data, finance: {...project.data.finance, workers: workers}}});
        await updateFinanceWorkerMethod(project.data.id, id, key, value);
        if (workerId) {
            document.getElementById('worker-inp-' + workerId).focus();
        }
        setNotification({type: 'success', text: 'Данные сохранены'});
    };

    const removeWorker = async (id) => {
        const workers = project.data.finance.workers.filter((worker) => worker.id !== id);

        await removeFinanceWorkerMethod(project.data.id, id);
        await changeFinance('estimatedSummarySpending|summarySpending', calculatePlanSpending(workers), calculateFactSpending(workers), workers);

        setNotification({type: 'success', text: 'Данные сохранены'});
    };

    const getCurrentParticipants = (type) => {
        const participants = project.data.participants.filter((participant) => participant.type === type);
        const collection = [];

        for (const participant of participants) {
            for (const user of users.data) {
                if (user.id === participant.userId) {
                    collection.push(user.avatarId !== null
                        ? {
                            isValid: true,
                            key: user.id,
                            imageUrl: process.env.REACT_APP_API_URL + '/files/' + user.avatarId,
                            text: user.firstName + ' ' + (user.lastName ?? ''),
                        }
                        : {
                            isValid: true,
                            key: user.id,
                            text: user.firstName + ' ' + (user.lastName ?? ''),
                        })
                }
            }
        }

        return collection;
    }

    const columns = [
        {
            key: 'work',
            name: 'Вид работ',
            fieldName: 'work',
            minWidth: 200,
            maxWidth: 300,
            isRowHeader: true,
            data: 'string',
            onRender: (worker) => <div style={{width: 300}}><TextField type="text" value={worker.work} onChange={(e) => changeWorker(worker.id, 'work', e.target.value)} /></div>,
        },
        {
            key: 'username',
            name: 'Исполнитель',
            fieldName: 'username',
            minWidth: 200,
            maxWidth: 280,
            isRowHeader: true,
            data: 'string',
            isPadded: true,
            onRender: (worker) => {
                return <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
                    {worker.userId
                        ? <>
                            <Dropdown
                                style={{width: 240}}
                                placeholder="Выберите исполнителя"
                                options={getCurrentParticipants('WORKER')}
                                selectedKey={worker.userId}
                                onChange={(e, option) => changeWorker(worker.id, 'userId', option.key)}
                            />
                            {/*<NormalPeoplePicker*/}
                            {/*    styles={{root: {width: 300}}}*/}
                            {/*    onResolveSuggestions={() => getCurrentParticipants('WORKER')}*/}
                            {/*    onEmptyResolveSuggestions={() => getCurrentParticipants('WORKER')}*/}
                            {/*    className={'ms-PeoplePicker'}*/}
                            {/*    key={'normal'}*/}
                            {/*    selectionAriaLabel={'Selected contacts'}*/}
                            {/*    removeButtonAriaLabel={'Remove'}*/}
                            {/*    selectedItems={getCurrentParticipants('WORKER').filter(item => item.key === worker.userId)}*/}
                            {/*    onChange={async (values) => {*/}
                            {/*        if (values.length > 0) {*/}
                            {/*            await changeWorker(worker.id, 'userId', values[0].key);*/}
                            {/*        } else {*/}
                            {/*            await changeWorker(worker.id, 'userId', null);*/}
                            {/*        }*/}
                            {/*    }}*/}
                            {/*    itemLimit={1}*/}
                            {/*/>*/}
                            <FontIcon id={'other-inp-btn-' + worker.id} aria-label="Edit" iconName="InsertTextBox" style={{cursor: 'pointer', verticalAlign: '-webkit-baseline-middle', fontSize: 16}} onClick={() => setOtherInput({...otherInput, [worker.id]: true})} />
                            {otherInput[worker.id] && (
                                <Callout
                                    style={{width: 260}}
                                    role="dialog"
                                    gapSpace={0}
                                    target={`#${'other-inp-btn-' + worker.id}`}
                                    onDismiss={() => setOtherInput({...otherInput, [worker.id]: false})}
                                    setInitialFocus
                                >
                                    <div style={{padding: 20}}>
                                        <TextField
                                            type="text"
                                            value={worker.username}
                                            onChange={(e) => changeWorker(worker.id, 'username', e.target.value)}
                                        />
                                    </div>
                                </Callout>
                            )}
                        </>
                        : <>
                            <TextField
                                style={{width: 240}}
                                type="text"
                                value={worker.username}
                                onChange={(e) => changeWorker(worker.id, 'username', e.target.value)}
                                id={'worker-inp-' + worker.id}
                            />
                            <FontIcon id={'other-inp-btn-' + worker.id} aria-label="BulletedList" iconName="PeopleAdd" style={{cursor: 'pointer', verticalAlign: '-webkit-baseline-middle', fontSize: 16}} onClick={() => setOtherInput({...otherInput, [worker.id]: true})} />
                            {otherInput[worker.id] && (
                                <Callout
                                    styles={{root: {width: 260}}}
                                    role="dialog"
                                    gapSpace={0}
                                    target={`#${'other-inp-btn-' + worker.id}`}
                                    onDismiss={() => setOtherInput({...otherInput, [worker.id]: false})}
                                    setInitialFocus
                                >
                                    <div style={{padding: 20}}>
                                        <Dropdown
                                            placeholder="Выберите исполнителя"
                                            options={getCurrentParticipants('WORKER')}
                                            selectedKey={worker.userId}
                                            onChange={(e, option) => changeWorker(worker.id, 'userId', option.key)}
                                        />
                                        {/*<NormalPeoplePicker*/}
                                        {/*    onResolveSuggestions={() => getCurrentParticipants('WORKER')}*/}
                                        {/*    onEmptyResolveSuggestions={() => getCurrentParticipants('WORKER')}*/}
                                        {/*    className={'ms-PeoplePicker'}*/}
                                        {/*    key={'normal'}*/}
                                        {/*    selectionAriaLabel={'Selected contacts'}*/}
                                        {/*    removeButtonAriaLabel={'Remove'}*/}
                                        {/*    selectedItems={getCurrentParticipants('WORKER').filter(item => item.key === worker.userId)}*/}
                                        {/*    onChange={async (values) => {*/}
                                        {/*        if (values.length > 0) {*/}
                                        {/*            await changeWorker(worker.id, 'userId', values[0].key);*/}
                                        {/*        } else {*/}
                                        {/*            await changeWorker(worker.id, 'userId', null);*/}
                                        {/*        }*/}
                                        {/*    }}*/}
                                        {/*    itemLimit={1}*/}
                                        {/*/>*/}
                                    </div>
                                </Callout>
                            )}
                        </>
                    }
                </div>;
            },
        },
        {
            key: 'planPrice',
            name: 'Расходы (план)',
            fieldName: 'planPrice',
            minWidth: 260,
            maxWidth: 260,
            isRowHeader: true,
            data: 'string',
            onRender: (worker) => {
                let canChangeByTime = true;
                if (worker.planPriceCreatedAt) {
                    const hoursPassed = Math.abs(Date.now().valueOf() - new Date(worker.planPriceCreatedAt).valueOf()) / 3600000;
                    canChangeByTime = hoursPassed <= 24;
                }
                return <div style={{width: 260}}>
                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <div style={{width: 140}}>
                            <MoneyField value={worker.planPrice ?? null} onChange={async (e) => {
                                await changeWorker(worker.id, 'planPrice', e.target.value);
                                await changeFinance('estimatedSummarySpending', calculatePlanSpending(project.data.finance.workers));
                            }} disabled={!canChangeFinanceFact && !canChangeByTime} />
                        </div>
                        <Dropdown style={{width: 120}}
                                  disabled={!canChangeFinanceFact && !canChangeByTime}
                                  options={[
                                      { key: 'без налога', text: 'без налога' },
                                      { key: 'с налогом', text: 'с налогом' },
                                  ]}
                                  selectedKey={worker.priceType ?? 'без налога'}
                                  onChange={async (e, option) => {
                                      if (worker.priceType === undefined && option.key === 'без налога') {
                                          await changeWorker(worker.id, 'priceType', option.key);
                                          return;
                                      }

                                      if (option.key === 'с налогом' && worker.planPrice) {
                                          await changeWorker(worker.id, 'priceType', option.key);
                                          await changeWorker(worker.id, 'planPrice', Math.round(worker.planPrice / 0.94));
                                          await changeFinance('estimatedSummarySpending', calculatePlanSpending(project.data.finance.workers));
                                          return;
                                      }

                                      if (option.key === 'без налога' && worker.planPrice) {
                                          await changeWorker(worker.id, 'priceType', option.key);
                                          await changeWorker(worker.id, 'planPrice', Math.round(worker.planPrice * 0.94));
                                          await changeFinance('estimatedSummarySpending', calculatePlanSpending(project.data.finance.workers));
                                          return;
                                      }
                                  }}
                        />
                    </Stack>
                    {worker.planPriceCreatedAt ? <div style={{fontSize: 12, marginTop: 3, marginLeft: 2, fontWeight: 600, color: '#a7a7a7'}}>Внесено: {formatDateTime(new Date(worker.planPriceCreatedAt))}</div> : <></>}
                    {worker.planPriceUpdatedAt ? <div style={{fontSize: 12, marginTop: 3, marginLeft: 2, fontWeight: 600, color: '#a7a7a7'}}>Изменено: {formatDateTime(new Date(worker.planPriceUpdatedAt))}</div> : <></>}
                </div>;
            },
        },
        {
            key: 'grossPrice',
            name: 'Расходы (факт)',
            fieldName: 'grossPrice',
            minWidth: 200,
            maxWidth: 200,
            isRowHeader: true,
            data: 'string',
            onRender: (worker) => <div style={{width: 200}}>
                <MoneyField value={worker.grossPrice} onChange={async (e) => {
                    await changeWorker(worker.id, 'grossPrice', e.target.value);
                    await changeFinance('summarySpending', calculateFactSpending(project.data.finance.workers));
                }} disabled={!canChangeFinanceFact} />
                {worker.grossPriceUpdatedAt ? <div style={{fontSize: 12, marginTop: 3, marginLeft: 2, fontWeight: 600, color: '#a7a7a7'}}>Изменено: {formatDateTime(new Date(worker.grossPriceUpdatedAt))}</div> : <></>}
            </div>,
        },
        {
            name: '',
            isRowHeader: true,
            data: 'string',
            onRender: (worker) => {
                let canChangeByTime = true;
                if (worker.planPriceCreatedAt) {
                    const hoursPassed = Math.abs(Date.now().valueOf() - new Date(worker.planPriceCreatedAt).valueOf()) / 3600000;
                    canChangeByTime = hoursPassed <= 24;
                }

                if (!canChangeFinanceFact && !canChangeByTime) {
                    return <></>;
                }

                return <FontIcon aria-label="Cancel" iconName="Cancel" style={{cursor: 'pointer', verticalAlign: '-webkit-baseline-middle'}} onClick={async () => {
                    await removeWorker(worker.id);
                }} />;
            }
        },
    ];

    const formatDate = (date) => {
        if (!date) return '';
        const month = datePickerStringsRu.monthDeclinations[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();

        return `${day} ${month} ${year}`;
    };

    const formatDateTime = (date) => {
        if (!date) return '';
        const month = datePickerStringsRu.shortMonths[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();
        const hours = `${date.getHours()}`.padStart(2, "0");
        const minutes = `${date.getMinutes()}`.padStart(2, "0");

        return `${day} ${month} ${year} в ${hours}:${minutes}`;
    }

    const calculateFactSpending = (workers) => {
        let spendingOnWorkers = 0;
        workers.forEach((worker) => {
            if (worker.grossPrice !== '') {
                spendingOnWorkers += parseFloat(worker.grossPrice ?? 0);
            }
        });
        return spendingOnWorkers === 0 ? null : spendingOnWorkers;
    };

    const calculatePlanSpending = (workers) => {
        let spendingOnWorkers = 0;
        workers.forEach((worker) => {
            if (worker.planPrice !== '') {
                spendingOnWorkers += parseFloat(worker.planPrice ?? 0);
            }
        });
        return spendingOnWorkers === 0 ? null : spendingOnWorkers;
    };

    const financeDisabled = project.data.finance.prepayment === null || project.data.finance.postPayment === null;

    const [prepaymentForm, setPrepaymentForm] = useState({id: project.data.id, summaryPrice: null, prepaymentSum: null, paymentType: null, services: project.data.services.map((service) => service.name)});

    const makePrepayment = async () => {
        const response = await makePrepaymentMethod(prepaymentForm.id, prepaymentForm.paymentType, prepaymentForm.summaryPrice, prepaymentForm.prepaymentSum, prepaymentForm.services);

        if (response.status === 'ok') {
            reloadData();
        }
    };

    if (financeDisabled) {
        return <Stack horizontal tokens={{ childrenGap: 30 }}>
            <Stack tokens={{ childrenGap: 10 }} styles={{ root: { width: 300 } }}>
                <Dropdown
                    placeholder="Онлайн или по документам"
                    label="Тип платежа"
                    options={[
                        { key: 'ONLINE', text: 'Онлайн' },
                        { key: 'CLASSIC', text: 'По документам' },
                    ]}
                    selectedKey={prepaymentForm.paymentType}
                    onChange={(e, option) => setPrepaymentForm({...prepaymentForm, paymentType: option.key})}
                />
                <MoneyField label="Стоимость проекта" value={prepaymentForm.summaryPrice} onChange={(e) => setPrepaymentForm({...prepaymentForm, summaryPrice: e.target.value})} />
                <MoneyField label="Сумма предоплаты" value={prepaymentForm.prepaymentSum} onChange={(e) => setPrepaymentForm({...prepaymentForm, prepaymentSum: e.target.value})} />
                <PrimaryButton style={{marginTop: 30, height: 40}} text="Продолжить" onClick={makePrepayment} />
            </Stack>
        </Stack>;
    }

    const isEstimatedMarginTooLow = project.data.finance.estimatedMarginPercent <= 40;

    let actualSummaryPrice = 0;
    if (project.data.finance.prepayment.status === 'PAID') {
        actualSummaryPrice += parseInt(project.data.finance.prepayment.sum);
    }

    if (project.data.finance.postPayment.status === 'PAID') {
        actualSummaryPrice += parseInt(project.data.finance.postPayment.sum);
    }

    project.data.finance.paymentSteps.forEach((payment, i) => {
        if (payment.status === 'PAID') {
            actualSummaryPrice += parseInt(payment.sum);
        }
    });

    return <Stack vertical tokens={{ childrenGap: 10 }}>
        {isShorty
            ? <></>
            : <>
                <Stack horizontal tokens={{ childrenGap: 30 }}>
                    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                        <MoneyField label="Доходы план" value={project.data.finance.summaryPrice} onChange={(e) => changeFinance('summaryPrice', e.target.value)} disabled={financeDisabled} />
                    </Stack>
                    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                        <MoneyField label="Доходы факт" value={actualSummaryPrice ?? null} disabled={true} />
                    </Stack>
                </Stack>
                <Separator />
            </>
        }
        <div style={{ fontSize: FontSizes.size14, marginBottom: 6, fontWeight: 'bold' }}>Платежи</div>
        <Stack horizontal tokens={{ childrenGap: 30 }}>
            <div>
                {/*<div style={{ fontSize: FontSizes.size14, marginBottom: 6, fontWeight: 'bold' }}>{project.data.finance.prepayment.title ?? 'Предоплата'}</div>*/}
                <Stack tokens={{ childrenGap: 10 }}>
                    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                        <TextField label="Название" value={project.data.finance.prepayment.title ?? 'Предоплата'} disabled={true} style={{color: 'black'}} />
                    </Stack>
                    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                        <Dropdown
                            placeholder="Выберите"
                            label="Тип"
                            options={[
                                { key: 'ONLINE', text: 'Онлайн' },
                                { key: 'CLASSIC', text: 'По документам' },
                            ]}
                            selectedKey={project.data.finance.prepayment.type}
                            disabled={project.data.finance.prepayment.type === 'ONLINE' && project.data.finance.prepayment.status === 'PAID'}
                            styles={{ title: {color: 'black'}}}
                            onChange={(e, option) => changeFinance('prepaymentType', option.key)}
                        />
                    </Stack>
                    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                        <MoneyField label="Сумма предоплаты" value={project.data.finance.prepayment.sum} disabled={project.data.finance.prepayment.type === 'ONLINE' && project.data.finance.prepayment.status === 'PAID'} onChange={(e) => changeFinance('prepaymentSum', e.target.value)} />
                    </Stack>
                    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                        <Dropdown
                            placeholder="Выберите"
                            label="Статус"
                            options={[
                                { key: 'NOT_NEED', text: 'Не нужна' },
                                { key: 'WAITING_DOCS', text: 'Ожидает договор и счёт' },
                                { key: 'WAITING_PAYMENT', text: 'Ожидает оплаты' },
                                { key: 'VERIFYING', text: 'Клиент сказал, что оплатил, проверяем' },
                                { key: 'PAID', text: 'Оплачено' },
                            ]}
                            selectedKey={project.data.finance.prepayment.status}
                            disabled={project.data.finance.prepayment.type === 'ONLINE' && project.data.finance.prepayment.status === 'PAID'}
                            styles={project.data.finance.prepayment.type === 'ONLINE' && project.data.finance.prepayment.status === 'PAID' ? { title: {color: 'black'}} : {}}
                            onChange={(e, option) => changeFinance('prepaymentStatus', option.key)}
                        />
                    </Stack>
                    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                        {project.data.finance.prepayment.type === 'ONLINE' && project.data.finance.prepayment.status === 'PAID' && !enableToChangePrepaymentDate
                            ? <div onClick={() => setEnableToChangePrepaymentDate(true)}><TextField label="Дата оплаты" value={formatDate(new Date(project.data.finance.prepayment.paidAt))} disabled={true} style={{color: 'black'}} /></div>
                            : <DatePicker
                                firstDayOfWeek={DayOfWeek.Monday}
                                showWeekNumbers={false}
                                firstWeekOfYear={1}
                                showMonthPickerAsOverlay={true}
                                placeholder="Выберите дату"
                                ariaLabel="Выберите дату"
                                label="Дата оплаты"
                                strings={datePickerStringsRu}
                                formatDate={formatDate}
                                value={project.data.finance.prepayment.paidAt !== null ? new Date(project.data.finance.prepayment.paidAt) : null}
                                onSelectDate={(date) => changeFinance('prepaymentPaidAt', date.toDateString())}
                            />
                        }
                        {project.data.finance.prepayment.type === 'ONLINE' && project.data.finance.prepayment.status !== 'PAID'
                            && <DefaultButton text="Скопировать ссылку на оплату" iconProps={{ iconName: 'Copy' }} onClick={() => navigator.clipboard.writeText(process.env.REACT_APP_API_URL + '/online-payment/' + project.data.finance.prepayment.onlinePayment.id)  && setNotification({type: 'success', text: 'Ссылка на оплату скопирована'})} />
                        }
                    </Stack>
                </Stack>
            </div>
            {project.data.finance.postPayment.status === 'NOT_NEED'
                ? <></>
                : <>
                    <Separator vertical />
                    <div>
                        {/*<div style={{ fontSize: FontSizes.size14, marginBottom: 6, fontWeight: 'bold' }}>{project.data.finance.postPayment.title ?? 'Постоплата'}</div>*/}
                        <div style={{display: 'flex', justifyContent: 'flex-end', position: 'relative'}}>
                            <ActionButton style={{position: 'absolute', top: -20}} iconProps={{ iconName: 'Delete' }} allowDisabledFocus onClick={() => changeFinance('postPaymentStatus', 'NOT_NEED')} />
                        </div>
                        <Stack tokens={{ childrenGap: 10 }}>
                            <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                                <TextField label="Название" value={project.data.finance.postPayment.title ?? 'Постоплата'} disabled={true} style={{color: 'black'}} />
                            </Stack>
                            <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                                <Dropdown
                                    placeholder="Выберите"
                                    label="Тип"
                                    options={[
                                        { key: 'ONLINE', text: 'Онлайн' },
                                        { key: 'CLASSIC', text: 'По документам' },
                                    ]}
                                    selectedKey={project.data.finance.postPayment.type}
                                    styles={{ textField: {color: 'black'}}}
                                    onChange={(e, option) => changeFinance('postPaymentType', option.key)}
                                />
                            </Stack>
                            <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                                <MoneyField label="Сумма постоплаты" value={project.data.finance.postPayment.sum} onChange={(e) => changeFinance('postPaymentSum', e.target.value)} />
                            </Stack>
                            <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                                <Dropdown
                                    placeholder="Выберите"
                                    label="Статус"
                                    options={[
                                        { key: 'NOT_NEED', text: 'Не нужна' },
                                        { key: 'WAITING_DOCS', text: 'Ожидает договор и счёт' },
                                        { key: 'WAITING_PAYMENT', text: 'Ожидает оплаты' },
                                        { key: 'VERIFYING', text: 'Клиент сказал, что оплатил, проверяем' },
                                        { key: 'PAID', text: 'Оплачено' },
                                    ]}
                                    selectedKey={project.data.finance.postPayment.status}
                                    onChange={(e, option) => changeFinance('postPaymentStatus', option.key)}
                                />
                            </Stack>
                            <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                                <DatePicker
                                    firstDayOfWeek={DayOfWeek.Monday}
                                    showWeekNumbers={false}
                                    firstWeekOfYear={1}
                                    showMonthPickerAsOverlay={true}
                                    placeholder="Выберите дату"
                                    ariaLabel="Выберите дату"
                                    label="Дата оплаты"
                                    strings={datePickerStringsRu}
                                    formatDate={formatDate}
                                    value={project.data.finance.postPayment.paidAt !== null ? new Date(project.data.finance.postPayment.paidAt) : null}
                                    onSelectDate={(date) => changeFinance('postPaymentPaidAt', date.toDateString())}
                                    disabled={project.data.finance.postPayment.status === 'NOT_NEED'}
                                />
                                {project.data.finance.postPayment.type === 'ONLINE' && project.data.finance.postPayment.status !== 'PAID'
                                    && <DefaultButton text="Скопировать ссылку на оплату" iconProps={{ iconName: 'Copy' }} onClick={() => navigator.clipboard.writeText(process.env.REACT_APP_API_URL + '/online-payment/' + project.data.finance.postPayment.onlinePayment.id)  && setNotification({type: 'success', text: 'Ссылка на оплату скопирована'})} />
                                }
                            </Stack>
                        </Stack>
                    </div>
                </>
            }
            <PaymentSteps project={project} setProject={setProject} setNotification={setNotification} reloadData={reloadData} />
        </Stack>
        {isShorty
            ? <></>
            : <>
                <Separator />
                <div>
                    <div style={{ fontSize: FontSizes.size14, marginBottom: 6, fontWeight: 'bold' }}>РАСХОДЫ</div>
                    <div style={{fontSize: 14, marginTop: 3, marginBottom: 16, marginLeft: 2, fontWeight: 600, color: 'gray'}}>
                        Расходы считаются автоматически когда вносите данные в раскладку.
                        {isEstimatedMarginTooLow && <>
                            <br /><span style={{color: 'red'}}>Маржа должна быть выше 40%</span>
                        </>}
                        {/*<br/>Если вы случайно изменили начальное и корректное значение расходов, оно высветиться ниже - снова впишите его, чтобы вернуть.*/}
                    </div>
                    {/*<div style={{ fontSize: FontSizes.size14, marginBottom: 0, fontWeight: '600' }}>План</div>*/}
                    <Stack horizontal tokens={{ childrenGap: 30 }}>
                        <Stack styles={{ root: { width: 300 } }}>
                            <MoneyField style={{color: isEstimatedMarginTooLow ? 'red' : 'black'}} label="Расходы план" value={project.data.finance.estimatedSummarySpending} onChange={(e) => changeFinance('estimatedSummarySpending', e.target.value)} disabled={true} />
                            {estSpendsChanged && estSpendsBefore ? <div style={{fontSize: 12, marginTop: 3, marginLeft: 2, fontWeight: 600, color: '#a7a7a7'}}>Начальное значение: {new Intl.NumberFormat().format(estSpendsBefore)} руб.</div> : <></>}
                        </Stack>
                        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                            <MoneyField style={{color: isEstimatedMarginTooLow ? 'red' : 'black'}} label="Маржа план руб." value={project.data.finance.estimatedSummaryMargin} onChange={(e) => changeFinance('estimatedSummaryMargin', e.target.value)} disabled={true} />
                        </Stack>
                        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                            <TextField label="Маржа план %" type="number" suffix="%" value={project.data.finance.estimatedMarginPercent} onChange={(e) => changeFinance('estimatedMarginPercent', e.target.value)} disabled={true} style={{color: isEstimatedMarginTooLow ? 'red' : 'black'}} />
                        </Stack>
                    </Stack>
                </div>
                <div>
                    {/*<div style={{ fontSize: FontSizes.size14, marginBottom: 0, fontWeight: '600' }}>Факт</div>*/}
                    <Stack horizontal tokens={{ childrenGap: 30 }}>
                        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                            <MoneyField label="Расходы факт" value={project.data.finance.summarySpending} onChange={(e) => changeFinance('summarySpending', e.target.value)} disabled={true} />
                            {spendsChanged && spendsBefore ? <div style={{fontSize: 12, marginTop: 3, marginLeft: 2, fontWeight: 600, color: '#a7a7a7'}}>Начальное значение: {new Intl.NumberFormat().format(spendsBefore)} руб.</div> : <></>}
                        </Stack>
                        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                            <MoneyField label="Маржа факт руб." value={project.data.finance.summaryMargin} onChange={(e) => changeFinance('summaryMargin', e.target.value)} disabled={true} />
                        </Stack>
                        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                            <TextField label="Маржа факт %" type="number" suffix="%" value={project.data.finance.marginPercent} onChange={(e) => changeFinance('marginPercent', e.target.value)} disabled={true} style={{color: 'black'}} />
                        </Stack>
                    </Stack>
                </div>
                {/*<Separator />*/}
                <div style={{marginTop: 40}}>
                    <div style={{ fontSize: FontSizes.size14, marginBottom: 0, fontWeight: 'bold' }}>Раскладка</div>
                    {!canChangeFinanceFact &&
                        <div style={{fontSize: 14, marginTop: 3, marginBottom: 0, marginLeft: 2, fontWeight: 600, color: 'gray'}}>
                            План расходов в раскладке при начале внесения данных нужно закончить в течении 24 часов, далее доступ к изменениям плана и удалению элемента блокируется.
                        </div>}
                    <DetailsList
                        styles={{ root: { paddingTop: 0 } }}
                        items={project.data.finance.workers}
                        compact={false}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        getKey={(item, index) => item.id}
                        setKey="none"
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                    />
                    <div style={{marginTop: 10}}>
                        <ActionButton iconProps={{ iconName: 'Add' }} allowDisabledFocus onClick={async () => {await addFinanceWorkerMethod(project.data.id); reloadData();}}>
                            Добавить пункт
                        </ActionButton>
                    </div>
                </div>
                <div style={{marginTop: 40, marginBottom: 50}}>
                    <div style={{ fontSize: FontSizes.size14, marginBottom: 10, fontWeight: 'bold' }}>Комментарии</div>
                    <Comments project={project} reloadData={reloadData} type="FINANCE" setNotification={setNotification} />
                </div>
            </>
        }
    </Stack>;
}
