import {getAuthToken} from "../functions/authToken";

const serverUrl = process.env.REACT_APP_API_URL;

export default function exportFinancesToExcelMethod(startedAtFrom, startedAtTo, status, manager, services) {
    const searchParams = new URLSearchParams({
        startedAtFrom: startedAtFrom ? startedAtFrom : '',
        startedAtTo: startedAtTo ? startedAtTo : '',
        status,
        manager,
        services: services.join(',')
    });
    const url = new URL(serverUrl + '/api/v2/pc/export-finances-to-excel?' + searchParams);
    let filename = '';

    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getAuthToken()}`,
        }
    }).then(response => {
        const header = response.headers.get('Content-Disposition');
        const parts = header.split(';');
        filename = parts[1].split('=')[1].replaceAll("\"", "");
        return response.blob()
    }).then(data => {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = filename;
        a.click();
    });
}
